import React from 'react'
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react'
import { ValidatedForm } from 'remix-validated-form'

import { InputWithLabel } from '@/components/InputWithLabel/InputWithLabel'
import { PasswordInputWithLabel } from '@/components/PasswordInputWithLabel/PasswordInputWithLabel'
import { AppStateContainer } from '@/context/AppStateContainer'
import { loginValidator } from '@/lib/schema/LoginValidator'

const forms = [
  // {
  //   label: '会社番号',
  //   placeholder: '0123',
  //   name: 'companyId',
  //   required: true,
  // },
  {
    label: '従業員番号',
    placeholder: '0123',
    name: 'employeeCode',
    required: true,
  },
  {
    label: 'パスワード',
    placeholder: 'xxxxxxxx',
    name: 'password',
    required: true,
    type: 'password',
  },
]

export const Login: React.FC = () => {
  const { isOnline } = AppStateContainer.useContainer()

  const form = forms.map((form) => {
    if (form.type === 'password')
      return (
        <PasswordInputWithLabel
          key={form.name}
          label={form.label}
          name={form.name}
          placeholder={form.placeholder}
          required={form.required}
        />
      )
    return (
      <InputWithLabel
        key={form.name}
        label={form.label}
        name={form.name}
        placeholder={form.placeholder}
        required={form.required}
      />
    )
  })
  return (
    <>
      <Flex
        alignItems="center"
        direction="column"
        height="100vh"
        justifyContent="space-between"
      >
        {/* Top section */}
        <Flex
          alignItems="center"
          height="33.33vh"
          justifyContent="center"
          width="100%"
        >
          <Text fontSize={48} fontWeight="bold" textAlign="center">
            現場時録
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          height="33.33vh"
          justifyContent="center"
          width="100%"
        >
          <Box mx={8} width="100%">
            <ValidatedForm
              method="POST"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                }
              }}
              validator={loginValidator}
            >
              <Box marginBottom={4}>
                <Text fontSize={32} textAlign="center">
                  ログイン
                </Text>
              </Box>
              <VStack spacing={4}>{form}</VStack>
              <Box marginTop={4}>
                <Button
                  isDisabled={!isOnline}
                  name="_action"
                  type="submit"
                  value="Sign In"
                  width="100%"
                >
                  ログイン
                </Button>
              </Box>
            </ValidatedForm>
          </Box>
        </Flex>

        <Flex
          alignItems="flex-end"
          height="33.33vh"
          justifyContent="center"
          pb={4}
          width="100%"
        >
          <Text
            color="gray.500"
            fontFamily="Sansation, sans-serif"
            fontSize="sm"
            fontWeight="bold"
          >
            Copyright © Adwaa Co, Ltd.
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
