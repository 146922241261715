import React from 'react'
import { Role } from '@prisma/client'
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node'
import { json, useActionData } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { AuthorizationError } from 'remix-auth'
import { redirect } from 'remix-typedjson'

import { LoginFailed } from '@/errors'
import { useCustomToast } from '@/hooks/useCustomToast'
import { authenticator } from '@/services/auth/authenticator.server'
import { checkAuthentication } from '@/services/auth/authUtils'
import { Login } from '@/template/Login/Login'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await checkAuthentication(request, Role.USER)
  if (user != null) {
    throw redirect('/')
  }
  return user
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const context = {
    request,
  }
  Sentry.captureMessage('Login')

  try {
    return await authenticator.authenticate('login', request, {
      successRedirect: '/',
      context, // 追加
    })
  } catch (error) {
    if (error instanceof Response) return error
    if (error instanceof AuthorizationError) return { message: error.message }

    // サーバサイドのエラーをSentryに報告
    Sentry.captureException(error)
    console.log(error)
    return json({ message: 'Internal Server Error' }, { status: 500 })
  }
}

const Pages = () => {
  const { toast } = useCustomToast()
  const result = useActionData<typeof action>()
  React.useEffect(() => {
    if (result && result.message) {
      toast({ title: result.message, status: 'error' })
      Sentry.captureException(new LoginFailed(result.message))
    }
  }, [result])

  return <Login />
}

export default Pages
